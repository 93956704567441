<template>
  <g>
    <svg:style>
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-19,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke:#aeaeae;
        stroke-width:0.25px;
      }
      .cls-1,
      .cls-2,
      .cls-3 {
        stroke-miterlimit:10;
      }
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-19,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap:round;stroke-linejoin:round;
      }
      .cls-4{fill:url(#inox-gradient);}
      .cls-5{fill:url(#inox-gradient-2);}
      .cls-6{fill:url(#inox-gradient-3);}
      .cls-7{fill:url(#inox-gradient-4);}
      .cls-8{fill:url(#inox-gradient-5);}
      .cls-9{fill:url(#inox-gradient-6);}
      .cls-10{fill:url(#inox-gradient-7);}
      .cls-11{fill:url(#inox-gradient-8);}
      .cls-12{fill:url(#inox-gradient-9);}
      .cls-13{fill:url(#inox-gradient-10);}
      .cls-14{fill:url(#inox-gradient-11);}
      .cls-15{fill:url(#inox-gradient-12);}
      .cls-16{fill:url(#glass-pattern);}
      .cls-17{fill:url(#glass-pattern);}
      .cls-18{fill:url(#glass-pattern);}
      .cls-19{fill:url(#glass-pattern);}
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.33"
                    :y1="doorTopHeight1 + 150.96"
                    :x2="doorLeftWidth1 + 17.01"
                    :y2="doorTopHeight1 + 150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 39.46"
                    :y1="doorTopHeight1 + 70.8"
                    :x2="doorLeftWidth1 + 103.29"
                    :y2="doorTopHeight1 + 70.8"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    :x1="doorLeftWidth1 + 39.46"
                    :y1="doorTopHeight1 + 123.98"
                    :x2="doorLeftWidth1 + 103.29"
                    :y2="doorTopHeight1 + 123.98"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-3"
                    :x1="doorLeftWidth1 + 39.46"
                    :y1="doorTopHeight1 + 177.2"
                    :x2="doorLeftWidth1 + 103.29"
                    :y2="doorTopHeight1 + 177.2"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-4"
                    :x1="doorLeftWidth1 + 39.42"
                    :y1="doorTopHeight1 + 230.41"
                    :x2="doorLeftWidth1 + 103.33"
                    :y2="doorTopHeight1 + 230.41"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-5"
                    :x1="doorLeftWidth1 + 22.54"
                    :y1="doorTopHeight1 + 124.01"
                    :x2="doorLeftWidth1 + 43.58"
                    :y2="doorTopHeight1 + 124.01"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-6"
                    :x1="doorLeftWidth1 + 98.78"
                    :y1="doorTopHeight1 + 124.01"
                    :x2="doorLeftWidth1 + 119.89"
                    :y2="doorTopHeight1 + 124.01"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-7"
                    :x1="doorLeftWidth1 + 22.54"
                    :y1="doorTopHeight1 + 70.97"
                    :x2="doorLeftWidth1 + 43.58"
                    :y2="doorTopHeight1 + 70.97"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-8"
                    :x1="doorLeftWidth1 + 98.78"
                    :y1="doorTopHeight1 + 70.97"
                    :x2="doorLeftWidth1 + 119.89"
                    :y2="doorTopHeight1 + 70.97"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-9"
                    :x1="doorLeftWidth1 + 22.54"
                    :y1="doorTopHeight1 + 177.06"
                    :x2="doorLeftWidth1 + 43.58"
                    :y2="doorTopHeight1 + 177.06"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-10"
                    :x1="doorLeftWidth1 + 98.78"
                    :y1="doorTopHeight1 + 177.06"
                    :x2="doorLeftWidth1 + 119.89"
                    :y2="doorTopHeight1 + 177.06"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-11"
                    :x1="doorLeftWidth1 + 22.54"
                    :y1="doorTopHeight1 + 230.14"
                    :x2="doorLeftWidth1 + 43.58"
                    :y2="doorTopHeight1 + 230.14"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-12"
                    :x1="doorLeftWidth1 + 98.78"
                    :y1="doorTopHeight1 + 230.14"
                    :x2="doorLeftWidth1 + 119.89"
                    :y2="doorTopHeight1 + 230.14"
                    xlink:href="#handle-gradient"/>

    <g id="H07">
      <!-- <g v-if="showVariant === 'inside' && showInox">
        <polyline id="inner-inox"
                  class="cls-4"
                  :points="`
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 60.15}
                    ${doorLeftWidth1 + 39.46} ${doorTopHeight1 + 60.15}
                    ${doorLeftWidth1 + 39.46} ${doorTopHeight1 + 81.45}
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 81.45}
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 60.15}
                  `"/>

        <polyline id="inner-inox-2"
                  data-name="inner-inox"
                  class="cls-5"
                  :points="`
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 113.33}
                    ${doorLeftWidth1 + 39.46} ${doorTopHeight1 + 113.33}
                    ${doorLeftWidth1 + 39.46} ${doorTopHeight1 + 134.63}
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 134.63}
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 113.33}
                  `"/>

        <polyline id="inner-inox-3"
                  data-name="inner-inox"
                  class="cls-6"
                  :points="`
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 166.58}
                    ${doorLeftWidth1 + 39.46} ${doorTopHeight1 + 166.58}
                    ${doorLeftWidth1 + 39.46} ${doorTopHeight1 + 187.81}
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 187.81}
                    ${doorLeftWidth1 + 103.29} ${doorTopHeight1 + 166.58}
                  `"/>

        <polyline id="inner-inox-4"
                  data-name="inner-inox"
                  class="cls-7"
                  :points="`
                    ${doorLeftWidth1 + 103.33} ${doorTopHeight1 + 219.76}
                    ${doorLeftWidth1 + 39.42} ${doorTopHeight1 + 219.76}
                    ${doorLeftWidth1 + 39.42} ${doorTopHeight1 + 241.06}
                    ${doorLeftWidth1 + 103.33} ${doorTopHeight1 + 241.06}
                    ${doorLeftWidth1 + 103.33} ${doorTopHeight1 + 219.76}
                  `"/>
      </g> -->

      <!-- <g v-if="showVariant === 'outside' && showInox"> -->
      <g v-if="showInox">
        <rect id="inox"
              class="cls-8"
              :x="inoxX1"
              :y="doorTopHeight1 + 123.08"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-2"
              data-name="inox"
              class="cls-9"
              :x="inoxX2"
              :y="doorTopHeight1 + 123.08"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-3"
              data-name="inox"
              class="cls-10"
              :x="inoxX1"
              :y="doorTopHeight1 + 70.03"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-4"
              data-name="inox"
              class="cls-11"
              :x="inoxX2"
              :y="doorTopHeight1 + 70.03"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-5"
              data-name="inox"
              class="cls-12"
              :x="inoxX1"
              :y="doorTopHeight1 + 176.12"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-6"
              data-name="inox"
              class="cls-13"
              :x="inoxX2"
              :y="doorTopHeight1 + 176.12"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-7"
              data-name="inox"
              class="cls-14"
              :x="inoxX1"
              :y="doorTopHeight1 + 229.24"
              :width="inoxW"
              height="1.81"/>

        <rect id="inox-8"
              data-name="inox"
              class="cls-15"
              :x="inoxX2"
              :y="doorTopHeight1 + 229.24"
              :width="inoxW"
              height="1.81"/>
      </g>

      <rect id="glass"
            class="cls-16"
            :x="doorLeftWidth1 + 43.78"
            :y="doorTopHeight1 + 117.73"
            width="55.2"
            height="12.71"/>

      <rect id="glass-2"
            data-name="glass"
            class="cls-17"
            :x="doorLeftWidth1 + 43.78"
            :y="doorTopHeight1 + 64.62"
            width="55.2"
            height="12.78"/>

      <rect id="glass-3"
            data-name="glass"
            class="cls-18"
            :x="doorLeftWidth1 + 43.78"
            :y="doorTopHeight1 + 170.78"
            width="55.2"
            height="12.71"/>

      <rect id="glass-4"
            data-name="glass"
            class="cls-19"
            :x="doorLeftWidth1 + 43.78"
            :y="doorTopHeight1 + 223.82"
            width="55.2"
            height="12.78"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxH() {
      return this.showBg ?
        (this.doorHeight - this.inoxOffset * 2) :
        (this.doorHeight - this.leafOffset * 2)
    },
    inoxX1() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxX2() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset + this.inoxW + 55.2 :
        this.doorLeftWidth + this.leafOffset + this.inoxW + 55.2
    },
    inoxW() {
      return this.showBg ?
        (this.doorWidth - this.inoxOffset * 2 - 55.2) / 2 :
        (this.doorWidth - this.leafOffset * 2 - 55.2) / 2
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
